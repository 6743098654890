@import '_variables';
@import '_mixins';
@import '_fonts';

// Reset
@import 'libs/normalize';
@import '_helpers';

// Charte
@import '_global';

//grille
@import '_grille';
@import '_grille_responsive';

@import '_owl-carousel';



#tt.vertical_top:after {
    left: 50% !important;
    @include transform(translateX(-50%));
}

// Slider post
.metaslider {
    .flexslider .flex-direction-nav li a {
        &.flex-next,
        &.flex-prev {
            background-image: url("../../assets/images/arrow-slider.png") !important;
            background-position: center center !important;
            width: 40px;
            height: 40px;
        }
        
        &.flex-prev {
            @include transform(rotate(-180deg));
        }
    }
    
    .caption-wrap {
        background: $couleur-2 !important;
    }
    
    .flex-control-paging li a {
        background: rgba($couleur-2, 0.5) !important;
        
        &.flex-active {
            background: $couleur-1 !important;
        }
    }
}

// Header
header {
    background: $white;
    z-index: 2000;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    
    .header-top {
        border-bottom: 0.1rem solid $couleur-4;
        
        nav {
            width: 3rem;
            display: inline-block;
            vertical-align: middle;
            z-index: 10;
            position: relative;
        }
		
		.website {
			.wpml-ls-legacy-dropdown-click {
				width: auto;
			}
		}
    }
    
    #menuToggle {
        display: block;
        position: relative;
        
        z-index: 1;
        user-select: none;
        
        input {
            display: block;
            width: 3rem;
            height: 2rem;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
            -webkit-touch-callout: none;
            
            &:checked ~ span {
                opacity: 1;
                @include transform (rotate(45deg) translate(0, -1px));
                width: 3rem;
            }
            
            &:checked ~ span:nth-last-child(3) {
                opacity: 0;
                @include transform(rotate(0deg));
            }
            
            &:checked ~ span:nth-last-child(2) {
                @include transform(rotate(-45deg) translate(0, 1px));
            }
        }
        
        .burger-icon {
            display: block;
            width: 3rem;
            height: 0.2rem;
            margin-bottom: 0.7rem;
            position: relative;
            
            background: $couleur-2;
            border-radius: 0.2rem;
            
            z-index: 1;
            transform-origin: 0.2rem 0;
            
            @include transition(transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                      background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                      opacity 0.55s ease);
            
            &:first-child {
                transform-origin: 0% 0%;
            }

            &:nth-last-child(2) {
                transform-origin: 0% 100%;
            }
            
            &:nth-child(3) {
                width: 2rem;
            }
        }
        
        .menu-burger {
            position: fixed;
            overflow-y: auto;
            @include box-shadow(0 0 10px 0 rgba(0,0,0,0.15));
            height: 100vh;
            top: 0;
            left: 0;
            
            background: $white;
            
            transform-origin: 0% 0%;
            @include transform(translate(-100%, 0));
            @include transition(transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0));
        }
    }
    
    .search {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        z-index: 2;
        
        .icon {
            font-size: 4rem;
            z-index: 1;
            position: relative;
            cursor: pointer;
            @include transition(all .5s ease);
        }
        
        input[type="checkbox"] {
            display: block;
            width: 4rem;
            height: 4rem;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
            -webkit-touch-callout: none;
            
            &:checked ~ .search-box {
                @include transform(translate(0, 0));
            }
            
            &:checked ~ .icon {
                color: $white;
            }
            
            
            &:checked ~ .search-box .search-input {
                transition-delay: 500ms;
                opacity: 1;
                left: 0;
            }
            
            
        }
        
        .search-box {
            background: $couleur-2;
            width: 100%;
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            transform-origin: 0% 0%;
            @include transform(translate(-100%, 0));
            @include transition(transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0));
            
            form {
                height: 100%;
                position: relative;
            
                .search-input {
                    position: relative;
                    top: 50%;
                    @include transform(translateY(-50%));
                    height: 80%;
                    color: $white;
                    background: none;
                    border: 0 none;
                    font-size: $font-size-xtall;
                    font-weight: $light;
                    @include transition(all .5s ease);
                    opacity: 0;
                    width: 100%;
                    left: -15%;
                    
                    &::-webkit-input-placeholder {
                        color: $white;
                    }
                    &::-moz-placeholder {
                        color: $white;
                    }
                    &:-ms-input-placeholder {
                        color: $white;
                    }
                    &:-moz-placeholder {
                        color: $white;
                    }
                }
            }
        }
    }
}

// Burger menu
.menu-burger {
    .main-nav-burger {
        padding-bottom: 3rem;
        margin-bottom: 3rem;
        border-bottom: 0.1rem solid $couleur-4;
    }
    
    ul {
        li {
            margin-bottom: 1.5rem;
            
            a {
                font-size: 2rem;
                color: $couleur-2;
                font-weight: $bold;
            }
            
            &.item-video {
                margin-top: 2rem;
                
                a {
                    background: $couleur-1;
                    padding: 0.2rem 1rem;
                    color: $white;
                }
            }
        }
    }
    
    .link {
        color: $couleur-1;
        font-weight: $bold;
        font-size: $font-size-medium;
        
        i {
            display: inline-block;
            vertical-align: middle;
            font-size: 2.5rem;
            margin-left: 0.5rem;
        }
    }
    
    .buttons {
        margin-top: 3rem;
        
        .button {
            display: block;
            float: left;
            margin-right: 1rem;
            border-radius: 5rem;
            width: 5rem;
            height: 5rem;
            line-height: 4.8rem;
            color: $white;
            text-align: center;
            
            .icon {
                font-size: 3.2rem;
                display: inline-block;
                vertical-align: middle;
            }
            
            &:nth-child(1) {
                background: $couleur-2;
            }
            
            &:nth-child(2) {
                background: #2286c5;
            }
        }
    }
}


// Article
.topic {
    z-index: 1;
    padding: 0.6rem 1.5rem;
    color: $white;
}

.post {
    
    .photo {
        @include box-shadow(0 0 10px 0 rgba(0,0,0,0.15));
        margin-bottom: 2rem;
        position: relative;
        padding-top: calc(1 / (16 / 9) * 100%);
        
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        
        .topic {
            position: absolute;
            left: 0;
            bottom: 2rem;
        }
    }
    
    .date {
        font-size: $font-size-base;
    }
    
    .excerpt {
        margin: 1rem 0;
        font-family: $font-family-2;
    }
}

.video {
    &:not(:last-child) {
        border-bottom: 1px solid $marron-light;
        margin-bottom: 4rem;
        padding-bottom: 4rem;
    }
    
    &:last-child {
        margin-bottom: 8rem;
    }
    
    h3 {
        margin-bottom: 2rem;
    }
    
    p {
        font-family: $font-family-2;    
    }
    
    .embed-container { 
		position: relative; 
		padding-bottom: 56.25%;
		overflow: hidden;
		max-width: 100%;
		height: auto;
		margin-top: 3rem;
	} 

	.embed-container iframe,
	.embed-container object,
	.embed-container embed { 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.tags {
    li {
        float: left;
        
        &:not(:last-child) {
            a:after {
                content: ',\0000a0';
            }
        }
        
        a {
            color: rgba($black, 0.5);
            font-size: $font-size-small;
            @include transition(all .5s ease);
            
            &:hover {
                color: rgba($black, 1);
            }
        }  
    }
}


// Slider 
.slider {
	overflow: hidden;
    article {
        .photo {
            &:after {
                content: "";
                position: absolute;
                top: 40%;
                right: 0;
                bottom: 0;
                left: 0;
                background: linear-gradient(to top,  rgba(0,0,0, 1) 0%,rgba(0,0,0, 0) 60%);
            }
        }
        
        .content {
            position: absolute;
            z-index: 1;
            left: 50%;
            @include transform(translateX(-50%));
            text-align: center;
            
            .h1 {
                color: $white;
                margin-top: 2.5rem;
                line-height: 1;
            }
        }
    }
    
    .owl-nav {
        button {
            width: 6rem;
            height: 6rem;
            background: rgba($black, 0.2);
            display: block;
            border: 0 none;
            border-radius: 6rem;
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            @include transition(all .5s ease);
            padding: 0;
            
            &.owl-prev {
                left: 5rem;
            }
            
            &.owl-next {
                right: 5rem;
            }
            
            .icon {
                color: $white;
                font-size: 3.5rem;
            }
        }
    }
}

.no-touch {
    .slider {
        .owl-nav {
            button:hover {
                background: rgba($black, 0.5);
            }
        }
    }
}

// Sidebar

aside {
	.title-plugin {
		font-size: 3rem;
		line-height: 3.4rem;
		font-weight: $light;
	}
	
	.newsletter {
		background: $marron;
		padding: 2.5rem;
		.title-plugin {
			color: $white;
		}
		.mc4wp-response {
			p {
				color: $white;
				font-size: 1.3rem;
			    margin: 0.5rem 0;
			    text-align: center;
			    a {
				    color: $white;
			    }
			}
		}
		.infos {
			color: $white;
			font-size: 1.7rem;
			font-family: $font-family-2;
			margin: 1.8rem 0 1.2rem;
			i {
				font-size: 4rem;
				display: block;
				float: left;
				margin-right: 1rem;
			}
		}
		
		.btn_news {
			background: rgba(255,255,255,0.2);
			border-radius: 5rem;
		    padding:0 .8rem 0 1.5rem;
		    font-size: 1.8rem;
		    border: none;
			color: $white;
			width: 100%;
			margin: auto;
			height: 4rem;
			position: relative;
			text-align: center;
			line-height: 4rem;
			cursor: pointer;
			.icon-arrow-right {
				background: $white;
				border-radius: 50%;
				width: 3rem;
				height: 3rem;
				position: absolute;
				top:.5rem;
				right:.8rem;
				color: $rouge;
				font-size: 2.8rem;
			    line-height: 3rem;
			    text-align: center;
			    cursor: pointer;
				@include transition(all 300ms linear);
			}
			&.open {
				.icon-arrow-right {
					@include transform(rotate(90deg));
				}
			}
		}
		
		#mc_embed_signup {
			display: none;
			margin-top: 2rem;
		}
		
		.valid-form {
    		margin-bottom: 2rem; 
    		
    		input {
        		float: left;
        		margin-right: 0.5rem;
    		}
    		
    		label {
        		line-height: 1.2 !important;
    		}
		}
		
		.indicates-required {
			color: $white;
			font-size: 1.3rem;
			span {
				color: $white;
				font-size: 1.3rem;
				font-weight: $regular;
			}
		}
		
		.infos-rgpd {
    		color: $white;
			font-size: 1.3rem;
			margin: 1rem 0;
			
			a {
    			color: $white;
    			text-decoration: underline;
			}
		}
		
		#mce-responses {
    		.response {
        		padding: 1rem;
        		border: 1px solid $white;
        		color: $white;
        		margin: 2rem 0;
    		}
		}
		
		.control-group {
			label {
				color: $white;
				display: block;
				line-height: 2.8rem;
				font-size: 1.4rem;
				.asterisk {
					color: $white;
					font-weight: $regular;
				}
			}
			
			input[type="text"],
			input[type="email"],
			select {
				background: $white;
			    padding:0 .8rem 0 1.5rem;
			    border: none;
				width: 100%;
				height: 4rem;
				margin-bottom: 2rem;
				font-size: 1.4rem;
				padding: 1rem;
			}
			
			input::placeholder {
                color: $marron;
                font-size: 1.8rem;
			}
		}
		
		input[type="submit"] {
			margin: 20px auto 0;
			display: table;
			border: none;
			font-family: $font-family;
			cursor: pointer;
		}
		
		.help-inline {
            span {
                color: $white;
            }
        }

		/*.form-nl {
			position: relative;

			input::placeholder {
                color: $white;
                font-size: 1.8rem;
			}
			
			.icon-arrow-right {
				background: $white;
				border-radius: 50%;
				width: 3.5rem;
				height: 3.5rem;
				position: absolute;
				right:.8rem;
				top:.8rem;
				color: $rouge;
				font-size: 3rem;
			    line-height: 3.5rem;
			    text-align: center;
			    cursor: pointer;
			    
				input[type="submit"] {
					position: absolute;
					right:.8rem;
					top:.8rem;
					border: none;
					background: none;
					cursor: pointer;
				}
			}
		}*/
	}
	
	.owl-nav {
		button {
			span {
				font-size: 3.2rem;
			}
		}
	}
	
	.publications {
		.title-publi.topic-color- {
			color: $couleur-1;
		}
		.btn.btn- {
			background: $couleur-1;
		}
	}

}


// Forms Plezzi 

[id*="foss"] {
    .checkbox {
        line-height: 1.2 !important;
        
        input[type="checkbox"] {
            margin-right: 5px !important;
        }
        
        span {
            font-size: 14px !important;
        }
    }
    
    .help-inline {
        span {
            font-family: $font-family !important;
            font-size: 14px !important;
            color: $couleur-2;
        }
        
        a {
            color: $couleur-1;
            text-decoration: underline;
        }
    }
    
    .plezi-submit-btn {
        @extend .btn;
        @extend .btn-red;
    }
}


// Post - SINGLE
.progress-container {
    width: 100%;
    height: 0.5rem;
    background: $couleur-4;
    
    .progress-bar {
        height: 0.5rem;
        background: $couleur-1;
        width: 0%;
    }
}

.single {
	.hero-post {
		h1 {
			color: $rouge;
			text-align: center;
			font-weight: $bold;
		}
		p {
			font-family: $font-family-2;
			text-align: center;
		}
		
		.video {
    		margin-bottom: 3.5rem;
    		
    		.embed-container {
        		padding-bottom: 50%;
    		}
		}
	}
	.infos-post {
		.date {
			font-size: 1.7rem;
		}
		.tags {
			border-bottom: 1px solid $marron-light;
		}
		.like-it {
			border-bottom: 1px solid $marron-light;
			p {
				font-weight: $bold;
				span {
					color: $black;
					opacity: 0.5;
					font-weight: $regular;
				}
			}
		}

	}
	.post-content {
		p {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
		
		a {
			color: $couleur-1;
			@include transition(all 0.3s ease);
			
			&:hover {
			    text-decoration: underline;
			}
			
			&.glossaryLink{
				&:hover {
					color: $marron;
				}
			}
			
			&[target="_blank"] {
				position: relative;
				text-decoration: none;
				
				&:after {
					content: "";
					position: absolute;
					z-index: -1;
					left:0;
					bottom: 0rem;
					height: 1rem;
					background: rgba($couleur-1,0.15);
					display: block;
					width: 100%;
					border-bottom: none;
					@include transition(all 0.3s ease);
				}
			}
		}
		
		
		.intro {
    		font-size: 2rem;
			font-weight: bold;
			margin-bottom: 10px;
		}
		
		h2 {
			color:$rouge;
			font-weight: $bold;
		}
		blockquote {
			background: $marron-light;
			border-left: .4rem solid $rouge;
			p {
				font-family: $font-family-2;
			}
		}
		
		img {
			display: block;
		}
		
		h5 {
    		font-style: italic;
		}
		
		h6 {
    		color: $couleur-1;
    		font-weight: $bold;
    		font-size: $font-size-base;
		}
		
		ul {
    		li {
    			&::before {
    				content: "";
    				display: inline-block;
    				vertical-align: middle;
    				width:.8rem;
    				height:.8rem;
    				background: $rouge;
    				margin-right: .8rem;
    			}
    		}
    	}
    	
    	.arrow-list {
			ul {
    			li {
        			font-weight: $bold;
        			font-size: 1.8rem;
        			margin-bottom: 1.5rem;
        			position: relative;
        			padding-left: 4rem;
        			
        			&::before {
            			display: none;
        			}
        			
        			&::after {
        				content: "\e902";
        				display: block;
        				font-family: 'icomoon';    
        				margin-right: 1rem;
        			    font-size: 3rem;
        			    line-height: 2.4rem;
        			    position: absolute;
        			    left: 0;
        			    top: 0;
        			}
    			}
			}
		}
		
		.take-home-points {
    		background: $marron-light;
    		padding: 3rem;
    		margin-top: 5rem;
    		
    		.titre {
    			color: $rouge;
    			text-transform: uppercase;
    			font-weight: $bold;
    			margin-bottom: 1.5rem;
    		}
    	}
	}
	
	.like-article {
		button {
			cursor: pointer;
			border: .1rem solid $couleur-1;
			padding: 1rem 2.5rem;
			background: none;
			border-radius: 3.5rem;
			font-weight: bold;
			color: $couleur-1;
            text-align: center;
            display: inline-block;
            @include transition(all 0.3s ease);
			&.like {
				border: .1rem solid $marron;
				color: $marron;
			}
			.icon {
    			font-size: 2rem;
    			display: inline-block;
    			vertical-align: middle;
    			margin-top: -0.4rem;
			}
			
			&:hover {
    			color: $couleur-1;
    			border-color: $couleur-1;
			}
		}
	}
	
	.post-experts {
    	margin-bottom: 2rem;
	}
	
	.post-comments {
    	margin-top: 6rem;
    	
    	.accordion_comments_header {
        	border: .1rem solid $marron;
			cursor: pointer;
			font-size: 2.5rem;
			line-height: 4rem;
			padding: 1.6rem 2rem;
			margin-bottom: 2rem;
			.arrow-comment {
				height: 4rem;
				width: 4rem;
				line-height: 4.4rem;
				background: $marron;
				color: $white;
				float: right;
				text-align: center;
				border-radius: 50%;
			}
		}
		
		.accordion_comments {
			margin-bottom: 4rem;
			display: none;
			
			.comment {
				margin-bottom: 2rem;
				padding-bottom: 2rem;
				border-bottom: 1px solid $marron-light;
				
				&:last-child {
					padding-bottom: 0;
					border-bottom: none;
				}
				.texte {
					font-size: 1.8rem;
				}
				.auteur {
					margin-top: .8rem;
					font-family: $font-family-2;
				}
			}
			
			.comment-form-comment {
    			a {
        			color: $couleur-1;
        			
        			&:hover {
            			text-decoration: underline;
        			}
    			}
			}
		}
        
		.logged-in-as {
    		margin-bottom: 1.5rem;
    		
			a {
				color: $rouge;
			}
		}	
		.comment-notes {
    		margin-bottom: .5rem;
    		
			p {
				display: block;
			}
		}
		.comment-form-comment,
		.comment-form-author,
		.comment-form-email,
		.comment-form-url {
    		margin-bottom: 1rem;
    		
			label {
				display: table;
				margin-bottom: .5rem;
			}
			input {
				width: 100%;
				height: 4rem;
				color: $marron;
				border: .1rem solid $marron;
				font-size: 1.5rem;
			}
			textarea {
				width: 100%;
				height: 13rem;
				resize: none;
				color: $marron;
				border: .1rem solid $marron;
				font-size: 1.5rem;
			}
		}
		
		.comment-form-email {
			label {
				span {
					display: block;
					font-size: 1.2rem;
				}
			}
		}
		
		.form-submit {
    		margin-top: 2rem;
    		
			input {
				@extend .btn;
    			@extend .btn-red;
    			font-family: $font-family;
			    display: table;
			    margin: auto;
			    cursor: pointer;
			}
		}
	}
		
	.related {
		border-top: 1px solid $marron-light;
	}
}




// Archives experts

.filter {
	.form-filter {
		background: $marron-light;
		label {
			float: left;
			font-weight: $bold;
		}
		select {
		    float: left;
		}
	}
}

.company-name-cat {
	&.diana-pet-food {
		color: $rouge;
	}
	&.partners {
		color: $orange;
	}
}


// List experts page

.list-experts {
    .sep-experts {
    	border-bottom: .1rem solid $marron-light;
    	
    	.no-experts {
        	margin-bottom: 3rem;
    	}
    }
}

// Expert
.post-experts {
    .circle {
    	margin: 0 auto;
    	img {
    		width: auto;
    	}
    }
	.expert_title {
		font-weight: $bold;
    	font-size: 2rem;
    	margin-top: 2rem;
		
		&.rouge {
			color:$rouge;
		}
		&.orange {
			color: $orange;
		}
	}
	.meta-expert {
		font-family: $font-family-2;
		font-size: $font-size-base;
    	margin-bottom: 2rem;
    	color: $couleur-2;
	}
}


// Single expert

.hero-expert {
	h1 {
		font-weight: $light;
		span {
			color: $rouge;
			font-weight: $bold;
		}
	}
	img {
		display: block;
	}
}

.categories {
	li {
		float: left;
		a {
			color: $white;
		}
	}
}

.infos-expert {
    .share-expert {
	    p {
		    font-weight: $bold;
	    }
	    ul {
		    li {
			    float: left;
			    a {
					color: $marron;
				}
		    }
	    }
    }
	
	.ancre-form-expert {
		white-space: inherit;
	}
}


.form-contact {
	background: $marron-tres-light;
	display: none;
	.titre-form-contact {
		.circle {
			float: left;
			display: flex;
			justify-content: center;
		}
		p {
			float: left;
			font-weight: $light;
			color: $rouge;
			span {
				font-weight: $bold;
			}
		}
	}
	form {
		label {
			color: $label;
		}
		span.wpcf7-list-item {
			margin: 0;	
		}
		input[type="checkbox"] {
			margin-right: 1rem;
			&::checked {
				background: $couleur-1;
			}
		}
		input[type="text"],
		input[type="email"] {
			border: .1rem solid $marron-light;
			width: 100%;
		}
		select  {
			border: .1rem solid $marron-light;
			width: 100%;
			background: $white
		}
		textarea {
			border: .1rem solid $marron-light;
			width: 100%;
			resize: none;
		}
		input[type="submit"] {
			@extend .btn;
			@extend .btn-red;
			font-family: $font-family;
		    display: table;
		    margin: auto;
            cursor: pointer;
            margin-top: 20px;
		}
		.infos-rgpd {
			font-size: 1.4rem;
			margin: 2rem 0 4rem;
			a {
				color: $couleur-1;
			}
		}
		#group-503 {
			margin-top: 3rem;
		}
		.subscribe {
			position: relative;
			label {
				span {
					margin-left:2rem;
					&:before {
						content: "";
						position: absolute;
					    top: .4rem;
					    left: 0;
					    height: 1.5rem;
					    width: 1.5rem;
					    background-color: $white;
					    border: .1rem solid $marron-light;
					    cursor: pointer;
					}
					&:after {
					    content: "";
					    position: absolute;
					    display: none;
					    left: .5rem;
					    top: .7rem;
					    width: .4rem;
					    height: .7rem;
					    border: solid white;
					    border-width: 0 .1rem .1rem 0;
					    -webkit-transform: rotate(45deg);
					    -ms-transform: rotate(45deg);
					    transform: rotate(45deg);
					}
				}
				input {
					position: absolute;
				    opacity: 0;
				    cursor: pointer;
					height: 2rem;
					&:checked ~ span:before {
						background-color: $couleur-1;
					}
					&:checked ~ span:after {
						display: block;
					}
				}
			}
		}
	}
}



// LES EXPERTS CATEGORY

.expert-categorie {
	background: $marron-light;
	
	.row {
    	font-size: 0;
    	
    	[class*="col-"] {
        	float: none;
        	display: inline-block;
        	vertical-align: top;
    	}
	}
	
	.term-name-expert {
		text-align: center;
		font-size: 2.5rem;
		margin-bottom: 4rem;
		
		span {
			font-weight: $bold;
		}
	}
	
	.post-experts {
    	margin-bottom: 8rem;
	} 
	
	.alm-btn-wrap {
    	margin-bottom: 0;
	}
}



// JUST FOR FUN

.just-fun {
	background: $marron-tres-light;
	border-top:.2rem solid rgba(94,81,77,0.1);
	padding: 4rem 2.5rem;
	.title-plugin {
		margin-bottom: 3rem;
		font-size: 3rem;
		line-height: 3.4rem;
	}
	
	.item {
		.title_post {
			font-weight: 700;
		}
		.text_post {
			font-family: $font-family-2
		}
		.source_post {
			font-size: 1.4rem;
			font-family: $font-family-2;
			opacity: 0.6;
		}
		.hidden-img {
			max-height: 17.5rem;
			overflow: hidden;
			margin: 1.5rem 0 1rem;
			@include box-shadow (0 0 10px 0 rgba(0,0,0,0.15));
			img {
				display: block;
			}
		}
	}
	.owl-nav {
		display: table;
		margin: auto;
		
		button {
			span {
				font-size: 3.2rem;
			}
		}
	}
}



// PAGE INDEX

.page-type {
	h1 {
	    font-weight: $light;
	}
	article {
		h2 {
			color: $rouge;
		    font-weight: $bold;
		}
		
		.infos {
		    font-size: 1.8rem;
			line-height: 2.4rem;
			margin-bottom: 6rem;
        }
		
		form {
			input[type="text"], 
			input[type="email"] {
			    border: 0.1rem solid $marron-light;
			    width: 100%;
			}
			select  {
				border: .1rem solid $marron-light;
				width: 100%;
				background: $white;
			}
			textarea {
				border: 0.1rem solid $marron-light;
			    width: 100%;
			    resize: none;
			}
			input[type="submit"] {
    			@extend .btn;
    			@extend .btn-red;
    			font-family: $font-family;
			    display: table;
			    margin: auto;
			    cursor: pointer;
			    margin-top: 20px;
			}
		}
		
		a {
    		color: $couleur-1;
			text-decoration: underline;
			@include transition(all 0.3s ease);
			
			&:hover {
				text-decoration: underline;
			}
		}
		
	}
}



.glossaire {
	
	.glossary-term-bar {
		background: $marron-light;
		padding: 3rem 2rem;
		display: table;
		margin-bottom: 7rem;
		width: 100%;
		span {
			display: table-cell;
			font-size: 3rem;
			line-height: 2.6rem;
			text-transform: uppercase;
			a {
				font-weight: $bold;
				color: $marron;
				
			}
		}
	}
	
	.glossary-term-list  {
		.glossary-block {
			.glossary-letter {
				font-size: 10rem;
				color: $rouge;
				font-weight: $bold;
				line-height: 7.6rem;
				margin-bottom: 3rem;
				display: block;
			}
			ul {
				li {
					margin-bottom: 5.5rem;
					a {
						font-size: 4rem;
						margin-bottom: 2.5rem;
						font-weight: 300;
						color: $marron;
						display: block;
					}
				}
			}
		}
	}
	
	
}

.single-glossary {
	h1.post-title {
		color: $couleur-1;
		font-size: 5rem;
		margin-bottom: 2rem;
	}
}






// Footer
footer {
    background: $couleur-2;
    padding: 2.5rem 0;
    
    nav {
        li {      
            a {
                color: $white;
            }
        }
    }
    
    .button {
        display: inline-block;
        vertical-align: middle;
        margin-right: 2rem;
        border-radius: 5rem;
        width: 5rem;
        height: 5rem;
        line-height: 4.5rem;
        color: rgba($white, 0.6);
        text-align: center;
        border: 0.1rem solid rgba($white, 0.6);
        
        .icon {
            font-size: 3.2rem;
            display: inline-block;
            vertical-align: middle;
        }
    }
    
    .logo {
        display: inline-block;
        vertical-align: middle;
        width: 19rem;
        height: 3.2rem;
		
		svg {
			width: 19rem;
			height: 3.2rem;
		}
        
        .cls-1 {
            fill: rgba($white, 0.6);
        }
    }
}

.no-touch {
    footer {
        a {
            @include transition(all .5s ease);
            
            &:hover {
                opacity: 0.8;
            }
        }
    }
}



// 404
.error404 {
	.hero-post {
		& + p {
			margin-bottom: 40px;
		}
	}
}




@media print{
	
	header .header-bottom,
	header .progress-container,
	header .header-top .navigation,
	header .header-top .search,
	.breadcrumbs,
	footer,
	.single .like-article,
	.post-comments,
	.related {
		display: none;
	}
	
	header {
		position: relative;
		.txt-center {
			display: block;
			margin:2rem auto 4rem;
			float: none;
		}
	}
	
	.single {
		.hero-post {
			img {
				margin: 2rem 0 3.5rem;
			}
		}
		.post-content {
			img {
				max-width: 20rem;
			}
			blockquote {
				margin: 3rem 0;
				padding: 3rem 4rem;
			}
			ul {
				li {
					list-style: disc;
					display: block;
				}
			}
		}
	}
	
	.infos-post {
		margin-bottom: 3rem;
		.like-it,
		.share-post {
			display: none;
		}
	}
	
	.post-experts {
		margin-top: 4rem;
		.title-part {
		    margin-bottom: 3rem;
		    font-size: 2.5rem;
		}
		.txt-center {
			display: none;
		}
	}
	
}