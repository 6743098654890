/* Font en local */

@font-face {
    font-family: 'MiloOT-Ita';
    src: local('MiloOT-Ita'),
        url('#{$urlDossierFont}/MiloOT-Ita.woff2') format('woff2'),
        url('#{$urlDossierFont}/MiloOT-Ita.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MiloOT';
    src: local('MiloOT-Bold'),
        url('#{$urlDossierFont}/MiloOT-Bold.woff2') format('woff2'),
        url('#{$urlDossierFont}/MiloOT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'MiloOT-BoldIta';
    src: local('MiloOT-BoldIta'),
        url('#{$urlDossierFont}/MiloOT-BoldIta.woff2') format('woff2'),
        url('#{$urlDossierFont}/MiloOT-BoldIta.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'MiloOT';
    src: local('MiloOT'),
        url('#{$urlDossierFont}/MiloOT.woff2') format('woff2'),
        url('#{$urlDossierFont}/MiloOT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MiloOT';
    src: local('MiloOT-Light'),
        url('#{$urlDossierFont}/MiloOT-Light.woff2') format('woff2'),
        url('#{$urlDossierFont}/MiloOT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'MiloSerifOT-Ita';
    src: local('MiloSerifOT-Ita'),
        url('#{$urlDossierFont}/MiloSerifOT-Ita.woff2') format('woff2'),
        url('#{$urlDossierFont}/MiloSerifOT-Ita.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}



@font-face {
    font-family: 'icomoon';
    src:  url('#{$urlDossierFont}/icomoon.eot?kbkbec');
    src:  url('#{$urlDossierFont}/icomoon.eot?kbkbec#iefix') format('embedded-opentype'),
        url('#{$urlDossierFont}/icomoon.ttf?kbkbec') format('truetype'),
        url('#{$urlDossierFont}/icomoon.woff?kbkbec') format('woff'),
        url('#{$urlDossierFont}/icomoon.svg?kbkbec#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-bottom:before {
    content: "\e900";
}
.icon-arrow-left:before {
    content: "\e901";
}
.icon-arrow-right:before {
    content: "\e902";
}
.icon-letter:before {
    content: "\e903";
}
.icon-like:before {
    content: "\e904";
}
.icon-linkedin:before {
    content: "\e905";
}
.icon-mail:before {
    content: "\e906";
}
.icon-more:before {
    content: "\e907";
}
.icon-print:before {
    content: "\e908";
}
.icon-search:before {
    content: "\e909";
}
.icon-target:before {
    content: "\e90a";
}