html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}


html {
    box-sizing: border-box;
    font-size: $font-size-root;
    // voir https://github.com/twbs/bootstrap/issues/18543 (bug ergo IE 10 : interraction gênante entre la scrollbar et une eventuelle zone active à côté de la scrollbar)
    -ms-overflow-style: scrollbar;
    height: 100%;
    -webkit-overflow-scrolling: touch; // Pour avoir un défilement avec inertie sur les blocs avec scroll (ie : burger)
}

html *,
html *::before,
html *::after {
    box-sizing: border-box;
}

* {
    outline: none;
}


// Voir https://getbootstrap.com/getting-started/#support-ie10-width pour l'explication du hack.
@at-root {
    @-ms-viewport { width: device-width; }
}


//
/* !-- RESET HTML, BODY, ET PLUS -------------------------------------------------------------------- */
//
body {
    font-family: $font-family;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $body-color;
    background-color: $body-bg;
    min-height: 100vh;
    position: relative;
    margin: 0;
}

// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
    outline: none !important;
}

//RESET

html,
p,
ul,ol,
li,
dl,
dd {
	margin:0;
	padding:0;
}

a {
	text-decoration: none;
}

ul {
	li {
		list-style:none;
	}
}

figure {
    margin: 0;
}

@for $i from 1 through 6 {
    h#{$i} {
        font-weight: $regular;
        margin:0;
        line-height: 1.2;
    }
}

h1, .h1 {
    font-size: $font-size-xxtall;
    font-weight: $bold;
}

h2, .h2 {
    font-size: $font-size-xtall;
}

h3, .h3 {
    font-size: $font-size-tall;
    font-weight: $bold;
}

.row {
    .col-md-6:nth-child(2n+1) {
        clear: left;
    }
}

.active-link {
    position: relative;
    
    a {
        &:before {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    
    .title {
        @include transition(all .5s ease);
    }
}

img {
	&.alignright {
		float: right;
		margin-left: 3rem;
	}
	&.alignleft {
		float: left;
		margin-right: 3rem;
	}
	&.aligncenter {
		margin: 2rem auto;
		display: table;
	}
}

.b-lazy {
    @include transition(opacity 500ms ease-in-out);
    max-width: 100%;
    opacity: 0;
}

.b-lazy.b-loaded {
    opacity: 1;
}

.active-link-photo {
    .photo {
        overflow: hidden;
        position: relative;
        
        img {
            @include transition(all .5s ease);
        }
    }
}

.no-touch {
    .active-link {
        &:hover {
            .title {
                color: $couleur-1;
            }
        }
    }
    
    .active-link-photo {
        &:hover {
            .photo {
                img {
                    @include transition(all .3s ease);
                    @include transform(scale(1.05));
                }
            }
        }
    }
}



.circle {
	height: 15rem;
	width:15rem;
	border-radius: 50%;
	@include box-shadow (0 0 10px 0 rgba(0,0,0,0.15));
	overflow: hidden;
	position: relative;
	
	&.little {
		height: 10rem;
		width:10rem;
		
		img {
    		position: absolute;
    		left: 50%;
    		top: 50%;
    		@include transform(translate(-50%,-50%));
		}
	}
	&.max-little {
		height: 4rem;
		width:4rem;
	}
	&.center {
		margin: auto;
	}
	img {
		height: 100%;
		width:auto;
		max-width: 45rem;
	}
}




// Couleurs topics

// Color

.topic-color-blue-steel {
    color: $measurement;
}

.topic-color-purple {
    color: $palatability; 
}

.topic-color-green {
    color: $pet-food-protection; 
}

.topic-color-red {
    color: $rouge; 
}

.topic-color-blue {
    color: $health-nutrition; 
}

.topic-color-pink {
    color: $scent-care; 
}


//BG 10%

.topic-bg-10-blue-steel {
    background-color: rgba($measurement, 0.1);
}

.topic-bg-10-purple {
    background-color: rgba($palatability, 0.1); 
}

.topic-bg-10-green {
    background-color: rgba($pet-food-protection, 0.1); 
}

.topic-bg-10-red {
    background-color: rgba($rouge, 0.1); 
}

.topic-bg-10-blue {
    background-color: rgba($health-nutrition, 0.1); 
}

.topic-bg-10-pink {
    background-color: rgba($scent-care, 0.1); 
}

.topic-bg-10-mountain-lake {
  background-color: rgba($sustainability, 0.1); 
}


//BG 80%

.topic-bg-80-blue-steel {
    background-color: rgba($measurement, 0.8);
}

.topic-bg-80-purple {
    background-color: rgba($palatability, 0.8); 
}

.topic-bg-80-green {
    background-color: rgba($pet-food-protection, 0.8); 
}

.topic-bg-80-red {
    background-color: rgba($rouge, 0.8); 
}

.topic-bg-80-blue {
    background-color: rgba($health-nutrition, 0.8); 
}

.topic-bg-80-pink {
    background-color: rgba($scent-care, 0.8); 
}

.topic-bg-80-mountain-lake {
  background-color: rgba($sustainability, 0.8); 
}


.blue-steel {
    .post {
        .topic:not(.related-bg) {
            @extend .topic-bg-80-blue-steel;
        }
    }
}

.purple {
    .post {
        .topic:not(.related-bg) {
            @extend .topic-bg-80-purple;
        }
    }
}

.green {
    .post {
        .topic:not(.related-bg) {
            @extend .topic-bg-80-green;
        }
    }
}

.red {
    .post {
        .topic:not(.related-bg) {
            @extend .topic-bg-80-red;
        }
    }
}

.blue {
    .post {
        .topic:not(.related-bg) {
            @extend .topic-bg-80-blue;
        }
    }
}

.pink {
    .post {
        .topic:not(.related-bg) {
            @extend .topic-bg-80-pink;
        }
    }
}

.mountain-lake {
  .post {
    .topic:not(.related-bg) {
      @extend .topic-bg-80-mountain-lake;
    }
  }
}


// Border

.topic-border-blue-steel {
    border-color: $measurement;
}

.topic-border-purple {
    border-color: $palatability; 
}

.topic-border-green { 
    border-color: $pet-food-protection;
}

.topic-border-red {
    border-color: $rouge;
}

.topic-border-blue {
    border-color: $health-nutrition; 
}

.topic-border-pink { 
    border-color: $scent-care;
}

.topic-border-mountain-lake { 
  border-color: $sustainability;
}




// Boutons
.btn {
    display: inline-block;
    padding: 0.8rem 2.5rem;
    border-radius: 3.5rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    color: $white;
    font-weight: $bold;
    border: 0 none;
    font-size: $font-size-base;
    @include transition(all .3s ease);
    font-family: $font-family;
    
    &.btn-red {
        background: $couleur-1;
        
        &:hover {
            background: darken($couleur-1, 10%);
        }
    }
    
    &.btn-orange {
        background: $couleur-5;
        
         &:hover {
            background: darken($couleur-5, 10%);
        }
    }
    
    &.btn-brown {
        background: $couleur-2;
        
         &:hover {
            background: darken($couleur-2, 10%);
        }
    }
    
    &.btn-blue {
        background: $health-nutrition;
        
        &:hover {
            background: darken($health-nutrition, 10%);
        }
    }
    
    &.btn-blue-steel {
        background: $measurement;
        
        &:hover {
            background: darken($measurement, 10%);
        }
    }
    
    &.btn-purple {
        background: $palatability;
        
        &:hover {
            background: darken($palatability, 10%);
        }
    }
    
    &.btn-green {
        background: $pet-food-protection;
        
        &:hover {
            background: darken($pet-food-protection, 10%);
        }
    }
    
    &.btn-pink {
        background: $scent-care;
        
        &:hover {
            background: darken($scent-care, 10%);
        }
    }
    
    &.btn-mountain-lake {
      background: $sustainability;
      
      &:hover {
        background: darken($sustainability, 10%);
      }
    }
}


.title-red {
	color: $couleur-1;
}

.title-orange {
	color: $couleur-5;
}



// BREADCRUMBS

.breadcrumbs {
	margin-bottom: 1rem;
	margin-top: 3rem;
	opacity: 0.5;
	a, span {
		text-transform: uppercase;
		font-size: 1.4rem;
		color: $marron;
	}
	.breadcrumb_last {
		text-decoration: underline;
	}
}



// PLUGIN LIKE
.watch-action {
	.action-unlike, .status {
		display: none;
	}
}





.share-post {
	p {
		font-weight: $bold;
	}
	ul {
		li {
			a {
				color: $marron;
			}
		}
	}
}

// Ajax button
.alm-btn-wrap {
    text-align: center;
    margin-bottom: 4rem;
    
    .alm-load-more-btn {
        color: $couleur-2;
        background: none;
        text-transform: uppercase;
        font-family: $font-family;
        font-size: $font-size-base;
        border: 0 none;
        cursor: pointer;
        @include transition(all .3s ease);
        position: relative;
        padding-top: 5rem;
        
        &:before {
            content: '\e907';
            font-family: 'icomoon';
            width: 4.4rem;
            height: 4.4rem;
            line-height: 44px;
            display: block;
            background: $couleur-2;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 50%;
            color: $white;
            font-size: 2rem;
            @include transform(translateX(-50%));
            @include transition(all .3s ease);
        }
        
        &:hover {
            color: $couleur-1;
            
            &:before {
                background: $couleur-1;
            }
        }
        
        &.done{
            display: none;
        }
    }
}

.box-erreur {
    margin-bottom: 4rem;
}

.margin-bottom {
    margin-bottom: 2rem;
}


