$urlDossierFont: 			'../fonts' !default;

// COLOR
$white:                 #ffffff !default;
$black:                 #000000 !default;
$rouge:                 #e63323 !default;
$marron:                #5e514d !default;
$marron-light:          #efedec !default;
$marron-tres-light:     #f9f8f7 !default;
$orange:                #ff8300 !default;
$label:					#4d4d4d !default;

$palatability:          #5d0855 !default;
$health-nutrition:      #00a7b5 !default;
$pet-food-protection:   #007734 !default;
$scent-care:            #e2007a !default;
$measurement:           #0068a1 !default;
$sustainability:        #009993 !default;

$couleur-1:             $rouge !default;
$couleur-2:             $marron !default;
$couleur-3:             $marron-light !default;
$couleur-4:             $marron-tres-light !default;
$couleur-5:             $orange !default;


$body-color:            $couleur-2 !default;
$body-bg:               $white !default;

// FONT FAMILY
$font-family: 'MiloOT', Arial, sans-serif;
$font-family-2: 'MiloSerifOT-Ita', Arial, sans-serif;

// FONT WEIGHT
$light:     300;
$regular:   400;
$bold:      700;

$font-size-root: 10px !default;

$font-size-xsmall: 1.2rem !default;
$font-size-small: 1.4rem !default;
$font-size-base: 1.6rem !default;
$font-size-medium: 1.8rem !default;
$font-size-tall: 2.5rem !default;
$font-size-xtall: 3rem !default;
$font-size-xxtall: 4rem !default;

$line-height-base: 1.3 !default;

// GRILLE ------------------------------------------------------------------------------------------------------------------
$enable-flex: false;
$enable-grid-classes: true;
$grid-gutter-width-base: 2rem;
$grid-gutter-widths: () !default;
$container-max-widths: 100rem;

$padding-main-mobile: 1.5rem;
$grid-breakpoints: (
    lg: 128rem
) !default;


// RUPTURES ------------------------------------------------------------------------------------------------------------------
$min-breakpoint-mobile: 768px;
$min-breakpoint-tablet: 1024px;
$min-breakpoint-desktop: 1281px;

$max-breakpoint-mobile: 767px;
$max-breakpoint-tablet: 1023px;
$max-breakpoint-desktop: 1280px;
