//
// utilitaires
//

.clearfix {
	@include clearfix();
}

.hidden {
    display: none !important;
    visibility: hidden; // Les lecteurs d'écrans parfois ne prennent pas en compte le display none
}

//position
.relative {
	position: relative;
}

.txt-right {
    text-align: right;
}

.txt-center {
    text-align: center;
}

.visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

.photo {
    img {
        vertical-align: middle;
        width: 100%;
        height: auto;
        max-width: inherit;
    }
}